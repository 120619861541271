<template>
	<v-container>
		<v-row>
			<v-col>
				<v-card class="pa-6">
					<v-card-title>DIAGNÓSTICO ACTUAL : Pneumonía adquirida en la comunidad ( J18.9 - Neumonía, microorganismo no especificado) </v-card-title>
					<v-row>
						<div class="col-xs-12 col-sm-6 col-md-3">
							<v-row>
								<v-col>
									Edad
								</v-col>
								<v-col>
									56 años
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									Género
								</v-col>
								<v-col>
									Hombre
								</v-col>
							</v-row>
							<v-row>
								<v-col>Médico Titular</v-col>
								<v-col>Escamilla E. (Col.2234337866E)</v-col>
							</v-row>
						</div>
						<div class="col-xs-12 col-sm-6 col-md-2">
							<v-btn>GESTION DE ALERTAS</v-btn>
						</div>
					</v-row>	
					<hr>
					<br>
					<br>
					<div class="grid1" style="font-weight:bold;">
						Medicación
					</div>
					<div class="containertable">

						<div class="grid5">
							<div class="boldText">
								1
							</div>
							<div class="boldText">
								Código Nacional
							</div>
							<div class="boldText">
								Descripción de la presentación
							</div>
							<div class="boldText">ATC</div>
							<div class="boldText">DCSA</div>
							<div></div>
							<div>708800</div>
							<div>SIMVASTATINA CINFA 40 mg COMPRIMIDOS RECUBIERTOS CON PELICULA EFG, 28 comprimidos</div>
							<div>C10AA01 - SIMVASTATINA</div>
							<div>96304005 - simvastatina</div>
						</div>
						<div class="grid8">
							<div></div>
							<div class="boldText">DOSIS</div>
							<div class="boldText">POSOLOGÍA</div>
							<div class="boldText">FRECUENCIA</div>
							<div class="boldText">TOMA</div>
							<div class="boldText">DURACIÓN</div>
							<div class="boldText">FECHA FINAL</div>
							<div class="boldText">CRÓNICO</div>
							<div></div>
							<div>40MG</div>
							<div>1 comprimido</div>
							<div>24H</div>
							<div>Cena (1comp)</div>
							<div></div>
							<div></div>
							<div>SÍ</div>

						</div>
						<div class="grid4">
							<div class="boldText"></div>
							<div class="boldText">Indicación</div>							
							<div class="">Hipercolesterolemia</div>
							<div class="">E78.2 - Hiperlipidemia mixta</div>

						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL PACIENTE</div>							
							<div class="">Consulte a su médico inmediatamente si presenta dolor, sensibilidad a la presión o debilidad de los músculos inexplicables. 	</div>


						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL FARMACÉUTICO</div>							
							
							

						</div>
						<v-row>						
							<v-col cols="12" sm="2">
								<button  class="btn btn-prescripcion">Generar aviso de prescripción</button>
							</v-col>
							<v-col cols="12" sm="2">
								<button class="btn btn-revision">Generar informe revisión</button>
							</v-col>
						</v-row>
					</div>

					<div class="containertable">

						<div class="grid5">
							<div class="boldText">
								2
							</div>
							<div class="boldText">
								Código Nacional
							</div>
							<div class="boldText">
								Descripción de la presentación
							</div>
							<div class="boldText">ATC</div>
							<div class="boldText">DCSA</div>
							<div></div>
							<div>665624
							</div>
							<div>DIAMICRON 60 mg COMPRIMIDOS DE LIBERACION MODIFICADA, 60 comprimidos
							</div>
							<div>A10BB09 - GLICLAZIDA
							</div>
							<div>325238000 - gliclazida
							</div>
						</div>
						<div class="grid8">
							<div></div>
							<div class="boldText">DOSIS</div>
							<div class="boldText">POSOLOGÍA</div>
							<div class="boldText">FRECUENCIA</div>
							<div class="boldText">TOMA</div>
							<div class="boldText">DURACIÓN</div>
							<div class="boldText">FECHA FINAL</div>
							<div class="boldText">CRÓNICO</div>
							<div></div>
							<div>60MG
							</div>
							<div>1 comprimido</div>
							<div>24H</div>
							<div>Desayuno (1comp)
							</div>
							<div></div>
							<div></div>
							<div>SÍ</div>

						</div>
						<div class="grid4">
							<div class="boldText"></div>
							<div class="boldText">Indicación</div>							
							<div class="">Diabetes mellitus de tipo 2</div>
							<div class="">E11.9 -  Diabetes mellitus tipo 2 sin complicaciones</div>

						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL PACIENTE</div>							
							<div class=""></div>


						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL FARMACÉUTICO</div>							
							
							

						</div>
						<v-row>						
							<v-col cols="12" sm="2">
								<button  class="btn btn-prescripcion">Generar aviso de prescripción</button>
							</v-col>
							<v-col cols="12" sm="2">
								<button class="btn btn-revision">Generar informe revisión</button>
							</v-col>
						</v-row>
					</div>

					<div class="containertable">

						<div class="grid5">
							<div class="boldText">
								3
							</div>
							<div class="boldText">
								Código Nacional
							</div>
							<div class="boldText">
								Descripción de la presentación
							</div>
							<div class="boldText">ATC</div>
							<div class="boldText">DCSA</div>
							<div></div>
							<div>973065
							</div>
							<div>ENALAPRIL CINFA 5 mg COMPRIMIDOS EFG , 60 comprimidos
							</div>
							<div>C09AA02 - ENALAPRIL
							</div>
							<div>15222008 - enalapril
							</div>
						</div>
						<div class="grid8">
							<div></div>
							<div class="boldText">DOSIS</div>
							<div class="boldText">POSOLOGÍA</div>
							<div class="boldText">FRECUENCIA</div>
							<div class="boldText">TOMA</div>
							<div class="boldText">DURACIÓN</div>
							<div class="boldText">FECHA FINAL</div>
							<div class="boldText">CRÓNICO</div>
							<div></div>
							<div>5MG
							</div>
							<div>1 comprimido</div>
							<div>24H</div>
							<div>Desayuno (1comp)
							</div>
							<div></div>
							<div></div>
							<div>SÍ</div>

						</div>
						<div class="grid4">
							<div class="boldText"></div>
							<div class="boldText">Indicación</div>							
							<div class="">Hipertensión esencial</div>
							<div class="">I10 - Hipertensión esencial (primaria)</div>

						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL PACIENTE</div>							
							<div class="">Vigilar estrechamente el control glucémico durante el primer mes	</div>


						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL FARMACÉUTICO</div>							
							
							

						</div>
						<v-row>						
							<v-col cols="12" sm="2">
								<button  class="btn btn-prescripcion">Generar aviso de prescripción</button>
							</v-col>
							<v-col cols="12" sm="2">
								<button class="btn btn-revision">Generar informe revisión</button>
							</v-col>
						</v-row>
					</div>

					<div class="containertable">

						<div class="grid5">
							<div class="boldText">
								4
							</div>
							<div class="boldText">
								Código Nacional
							</div>
							<div class="boldText">
								Descripción de la presentación
							</div>
							<div class="boldText">ATC</div>
							<div class="boldText">DCSA</div>
							<div></div>
							<div>706223
							</div>
							<div>LEVOFLOXACINO KRKA 500 MG COMPRIMIDOS RECUBIERTOS CON PELICULA EFG , 7 comprimidos
							</div>
							<div>J01MA12 - LEVOFLOXACINO

							</div>
							<div>96087006 - levofloxacino

							</div>
						</div>
						<div class="grid8">
							<div></div>
							<div class="boldText">DOSIS</div>
							<div class="boldText">POSOLOGÍA</div>
							<div class="boldText">FRECUENCIA</div>
							<div class="boldText">TOMA</div>
							<div class="boldText">DURACIÓN</div>
							<div class="boldText">FECHA FINAL</div>
							<div class="boldText">CRÓNICO</div>
							<div></div>
							<div>500MG

							</div>
							<div>1 comprimido</div>
							<div>24H</div>
							<div>Desayuno (1comp)
							</div>
							<div>7 días
							</div>
							<div></div>
							<div>NO
							</div>

						</div>
						<div class="grid4">
							<div class="boldText"></div>
							<div class="boldText">Indicación</div>							
							<div class="">Pneumonía adquirida en la comunidad </div>
							<div class="">J18.9 – Neumonía, microorganismo no especificado</div>

						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL PACIENTE</div>							
							<div class="">Al primer signo de dolor o inflamación de un tendón (por ejemplo, en el tobillo, la muñeca, el codo, el hombro o la rodilla), deje de tomar Levofloxacino Krka, póngase en contacto con su médico y mantenga en reposo la zona dolorosa.	</div>


						</div>
						<div class="grid3">
							<div class="boldText"></div>
							<div class="boldText">INDICACIONES AL FARMACÉUTICO</div>							
							
							

						</div>
						<v-row>						
							<v-col cols="12" sm="2">
								<button  class="btn btn-prescripcion">Generar aviso de prescripción</button>
							</v-col>
							<v-col cols="12" sm="2">
								<button class="btn btn-revision">Generar informe revisión</button>
							</v-col>
						</v-row>
					</div>




				</v-card>

			</v-col>
		</v-row>


	</v-container>

</template>
<style lang="scss" scoped>
.containertable{
	margin:20px;
	padding-top:10px;
	border-top:1px solid black;
}
.boldText{font-weight:bold;}
	[class^='grid']{
	display: grid;
	margin-bottom:30px;
	grid-gap:10px;

}
$mainColumn:100px;
$columnsWith:250px;
.grid1{
	grid-template-columns: $mainColumn;
}
.grid2{
	grid-template-columns: $mainColumn $columnsWith;
}

.grid3{
	grid-template-columns: $mainColumn repeat(2,minmax(auto, $columnsWith));
}

.grid4{
	grid-template-columns:$mainColumn repeat(3,minmax(auto, $columnsWith));
}


.grid5{
	grid-template-columns:$mainColumn repeat(4,minmax(auto, $columnsWith));
}
.grid6{
	grid-template-columns:$mainColumn repeat(5,minmax(auto, $columnsWith));
}
.grid7{
	grid-template-columns:$mainColumn repeat(6,minmax(auto, $columnsWith));
}
.grid8{
	grid-template-columns:$mainColumn repeat(7,minmax(auto, $columnsWith));
}
.btn{
	padding:10px;
	border-radius:10px;
	&:before {   
	border-radius: inherit;
	bottom: 0;
	color: inherit;
	content: "";
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity .2s cubic-bezier(.4,0,.6,1);
}
}
.btn-prescripcion{
	background-color:green;
	color:white;

}
.btn-revision{
	background-color: lightblue;
	color:white;
}
</style>
<script>
export default{

}
</script>
