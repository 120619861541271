<template>
	<v-container>
		<v-row><h3 class="titlePage">Posología y Dosis Máximas</h3></v-row>
		<v-row>
			<v-col>

				<v-card class="mx-auto">
					<v-card-title>Medicamento</v-card-title>
					<v-card-text>
						<v-simple-table>

							<tbody>
								<tr>
									<td>
										<v-row>
											<v-col>CN Medicamento:</v-col> <v-col>602742
											</v-col>
										</v-row>
										<v-row>
											<v-col>Descripción:</v-col> <v-col>Levofloxacino 500mg
											</v-col>
										</v-row>
										<v-row>
											<v-col>Código ATC OMS:</v-col> <v-col>2791A
											</v-col>
										</v-row>

										<v-row>
											<v-col>Descripción Principio  :</v-col> <v-col> Levofloxacina </v-col>
										</v-row>
										<v-row>
											<v-col>Vía :</v-col> <v-col>Oral</v-col>
										</v-row>


									</td>


								</tr>
							</tbody>
						</v-simple-table>	
					</v-card-text>
				</v-card>
			</v-col>
			<v-col>
				<v-card  class="mx-auto">
					<v-card-title>
						DOSIS
					</v-card-title>
					<v-card-text>
						<v-simple-table>
							<tbody>
								<tr>
									<td>
										<v-row v-for="(rowitem,i) in dosisData" :key="i">
											<v-col>{{ rowitem.title }} :</v-col> <v-col>{{ rowitem.value }}
											</v-col>
										</v-row>



									</td>


								</tr>
							</tbody>
						</v-simple-table>

					</v-card-text>

				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col col="12" md="9">
				<v-card  class="mx-auto">
					<v-card-title>


						POSOLOGÍA	 

					</v-card-title>
					<v-card-body class="bodycard">
						<v-row v-for="(rowitem,i) in posologiaData" :key="i">
							<v-col sm="6" md="4">{{ rowitem.title }} :</v-col> <v-col sm="6" md="4">{{ rowitem.value }}
							</v-col> 
						</v-row>
					</v-card-body>
				</v-card>
			</v-col>
			<v-col>
				<v-btn			
				class="button-alert"
				>
				Crear Alarma
			</v-btn>
		</v-col>
	</v-row>
</v-container>
</template>
<style></style>
<script>
export default{
	props:{

	},
	data:()=>(
	{
		dosisData:[
		{
			title:'Sector',
			value:''
		},
		{
			title:'Edad',
			value:''
		},
		{
			title:'Peso',
			value:''
		},
		{
			title:'Pauta Insuficiencia Renal',
			value:''
		},
		{
			title:'Pauta Insuficiencia Hepática',
			value:''
		},
		{
			title:'Código Tipo Dosis',
			value:''
		},
		{
			title:'Descripción Dosis',
			value:''
		},
		{
			title:'Dosis',
			value:''
		},
		{
			title:'Unidad',
			value:''
		},
		{
			title:'Nº Unidad',
			value:''
		},
		{
			title:'Código Unidad',
			value:''
		},
		{
			title:'Duración  ',
			value:''
		},



		],
		posologiaData:[
		{
			title:'Dosis'
		},
		{
			title:'Unidad Principio',
		}
		, {
			title:'Nº Unidades',
		},
		{
			title:'Código Unidad',
		},
		{
			title:'Intérvalo ',
		},{
			title:'Código Intérvalo',
		},{
			title:'Notas',
		},
		]
	}
	),
}
</script>
